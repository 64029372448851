<template>
  <component :is="commonComponent" id="terms" title="Terms of services">
    <section id="terms-content" class="pc_padding">
      <div class="common-content-head">
        <span>1</span> Acceptance of Terms
      </div>

      <div class="common-content-row">
        By accessing and using illustrations.photos (&quot;the Site&quot;), you agree to comply
        with and be bound by these User Terms and Conditions (&quot;Terms&quot;). If you do not
        agree with these Terms, you should not use the Site.
      </div>

      <div class="common-content-head">
        <span>2</span> Use of the Site
      </div>

      <div class="common-content-row">
        You may use the Site for personal, non-commercial purposes only. You agree not to use the
        Site for any unlawful or prohibited activities.
      </div>

      <div class="common-content-head">
        <span>3</span>Intellectual PropertyRights
      </div>

      <div class="common-content-row">
        All content on the Site, including images, text, graphics, and logos, is the property of
        illustrations.photos or its content suppliers and is protected by copyright and other
        intellectual property laws. You may not reproduce, distribute, or create derivative works
        from any content on the Site without prior written permission.
      </div>

      <div class="common-content-head">
        <span>4</span> User Conduct
      </div>

      <div class="common-content-row">
        You agree to use the Site in a manner that is lawful and respectful to others. You must not
        upload, post, or transmit any content that is offensive, defamatory, or infringes on the
        rights of others.
      </div>

      <div class="common-content-head">
        <span>5</span> Disclaimers
      </div>

      <div class="common-content-row">
        The Site is provided on an &quot;as-is&quot; and &quot;as-available&quot; basis.
        illustrations.photos makes no warranties, express or implied, regarding the Site&#39;s
        operation or the information, content, or materials included on the Site.
      </div>

      <div class="common-content-head">
        <span>6</span> Limitation of Liability
      </div>

      <div class="common-content-row">
        illustrations.photos will not be liable for any damages arising from the use or inability
        to use the Site, including but not limited to direct, indirect, incidental, punitive, and
        consequential damages.
      </div>

      <div class="common-content-head">
        <span>7</span> Indemnification
      </div>

      <div class="common-content-row">
        You agree to indemnify and hold harmless illustrations.photos, its affiliates, and their
        respective officers, directors, employees, and agents from any claims, liabilities, damages,
        and expenses (including attorney&#39;s fees) arising out of your use of the Site or your
        breach of these Terms.
      </div>

      <div class="common-content-head">
        <span>8</span> Changes to the Terms
      </div>

      <div class="common-content-row">
        illustrations.photos reserves the right to modify these Terms at any time. Any changes
        will be effective immediately upon posting on the Site. Your continued use of the Site after
        any changes signifies your acceptance of the revised Terms.
      </div>

      <div class="common-content-head">
        <span>9</span> Termination
      </div>

      <div class="common-content-row">
        illustrations.photos may terminate your access to the Site at any time, with or without
        cause, and without notice.
      </div>

      <div class="common-content-head">
        <span>10</span> Governing Law
      </div>

      <div class="common-content-row">
        These Terms are governed by and construed in accordance with the laws of the jurisdiction in
        which illustrations.photos operates, without regard to its conflict of law principles.
      </div>

      <div class="common-content-head">
        <span>11</span> Contact Information
      </div>

      <div class="common-content-row">
        If you have any questions or concerns about these Terms, please contact us at
        illustrationsphs.support@gmail.com.
      </div>
    </section>
  </component>
</template>

<script>
import common from '@/mixin/common.js';
import { mapGetters } from 'vuex';
// 按需动态引入 PC 和 Mobile 版本的 CommonPage
const PcCommon = () => import('@/pages/components/pc_common.vue');
const MobileCommon = () => import('@/pages/components/m_common.vue');

import '@/css/common.scss';

export default {
  mixins: [common],
  data () {
    return {
      commonComponent: null,
    };
  },
  metaInfo () {
    return {
      title: 'Terms of services | illustrations.photos',
      meta: [
        {
          name: 'description',
          content: 'Read the User Terms and Conditions for illustrations.photos. Understand the rules and guidelines for using our illustrations sharing platform.',
        },
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showDebug',
      'deviceType',
      'host',
    ])
  },
  created () {
    // 根据 deviceType 动态加载对应的组件
    this.commonComponent = this.deviceType === 'desktop' || this.deviceType === 'pc' ? PcCommon : MobileCommon;
  },
  async activated () {
    console.log(`${this.$options.name} Component activated-----------`);
  },
  async mounted () {
    console.log(`-------------------${this.$options.name} mounted`)
  },
  deactivated () {
    console.log(`${this.$options.name} Component deactivated-----------`);
  },
  beforeDestroy () {
    console.log(`-------------------${this.$options.name} beforeDestroy`);
  },
};
</script>